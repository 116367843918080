import Vue from 'vue'
import Flash from '@components/modal/slide-down-flash.vue'

export default Vue.extend({
  components: {
    Flash,
  },
  data() {
    return {
      showSlideDownFlash: false as boolean,
      slideDownFlashTimer: null as null | number,
    }
  },
  methods: {
    showSuccessFlash() {
      this.showSlideDownFlash = true
      this.slideDownFlashTimer && clearTimeout(this.slideDownFlashTimer)
      this.slideDownFlashTimer = setTimeout(() => {
        this.onSlideDownFlashHide()
      }, 4000)
    },
    onSlideDownFlashHide() {
      this.showSlideDownFlash = false
    },
  },
})
