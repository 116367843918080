import { DateTime } from 'luxon'

const now = DateTime.now()
const format1 = now.toFormat('HH:mm')
const format2 = now.toFormat('hh:mm a')

interface TimeSystemOption {
  key: string
  value: string
}

export const timeSystemOptions: Readonly<TimeSystemOption>[] = [
  {
    key: '24',
    value: `24hour (${format1})`,
  },
  {
    key: '12',
    value: `12hour (${format2})`,
  },
]
