import { DateTime } from 'luxon'

const today = DateTime.now()
const format1 = today.toFormat('yyyy-LL-dd')
const format2 = today.toFormat('LL-dd-yyyy')
const format3 = today.toFormat('dd-LL-yyyy')
const format4 = today.toFormat('MMMM dd, yyyy')

interface DateFormatOption {
  key: string
  value: string
}

export const dateFormatOptions: Readonly<DateFormatOption>[] = [
  {
    key: '%Y-%m-%d',
    value: `YYYY-MM-DD (${format1})`,
  },
  {
    key: '%m-%d-%Y',
    value: `MM-DD-YYYY (${format2})`,
  },
  {
    key: '%d-%m%-%Y',
    value: `DD-MM-YYYY (${format3})`,
  },
  {
    key: '%B %e, %Y',
    value: `Month Day, YYYY (${format4})`,
  },
]
