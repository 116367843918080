




































































import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
// @ts-ignore
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import slideDownFlashMixin from '@utils/mixins/slide-down-flash'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'
import { IData, IMethods, IComputed } from '@/types/addy_plus/accounts/details'
import { dateFormatOptions } from '@utils/data/date_format_options'
import timeZoneOptions from '@utils/data/time_zones'
import { timeSystemOptions } from '@utils/data/time_system_options'
import { encodeFileAsUrl } from '@utils/common-methods/common'
import { updateAccount } from '@api/addy-plus/accounts'

export default Vue.extend<IData, IMethods, IComputed>({
  title: 'Account Details',
  mixins: [pageTitleMixin, slideDownFlashMixin],
  components: {
    Breadcrumb,
  },
  data() {
    return {
      upload: null,
      logo: '',
      accountName: '',
      accountOwner: '',
      dateFormat: '',
      dateFormatOptions: Object.freeze(dateFormatOptions),
      timeZone: '',
      timeZoneOptions: Object.freeze(timeZoneOptions),
      timeSystem: null,
      timeSystemOptions: Object.freeze(timeSystemOptions),
      isSubmitting: false,
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['accounts']),
    accountId() {
      return this.$route.params.accountId
    },
    accountDetails() {
      return this.accounts.find((account) => account.id === this.accountId)
    },
  },
  watch: {
    accountDetails(): void {
      this.populateData()
    },
  },
  mounted() {
    this.populateData()
  },
  methods: {
    ...mapMutations('addyPlusBase', ['setAccounts']),
    populateData() {
      if (!this.accountDetails) return
      const { logo, name, ownerEmail, dateFormat, timeSystem, timeZone } = this.accountDetails
      this.logo = logo
      this.accountName = name
      this.accountOwner = ownerEmail
      this.dateFormat = dateFormat
      this.timeSystem = timeSystem
      this.timeZone = timeZone
    },
    onLogoUpload(file) {
      encodeFileAsUrl(file, (url) => {this.logo = url})
    },
    onSave() {
      if (!this.accountName) return

      this.isSubmitting = true
      updateAccount(this.accountId, this.generateUpdatePayload()).then((res) => {
        this.isSubmitting = false
        if (!res.success) return
        this.updateStoredAccounts(res.data)
        this.scrollToPageTop()
        // @ts-ignore
        this.showSuccessFlash()
      })
    },
    updateStoredAccounts(accountDetails) {
      const index = this.accounts.findIndex((account) => account.id === accountDetails.id)
      const accountsCopy = JSON.parse(JSON.stringify(this.accounts))
      accountsCopy.splice(index, 1, accountDetails)
      // TODO: remove the annotation once src/store/modules/addyPlusBase.js is converted to .ts
      // @ts-ignore
      this.setAccounts(accountsCopy)
    },
    generateUpdatePayload() {
      const formData = new FormData()
      formData.append('account[account_name]', this.accountName)
      formData.append('account[date_format]', this.dateFormat)
      formData.append('account[time_zone]', this.timeZone)
      this.upload && formData.append('account[account_logo]', this.upload)
      this.timeSystem && formData.append('account[time_system]', this.timeSystem)

      return formData
    },
    scrollToPageTop() {
      document.querySelector('.addy-plus-body > main')!.scrollTop = 0
    },
  },
})
